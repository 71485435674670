import axios from "axios";
// export const baseUrl = process.env.REACT_APP_BASE_URL;
// export const baseUrl =  ((/localhost/i.test(JSON.stringify(window.location.href))) || (/heroku/i.test(JSON.stringify(window.location.href)))) ? 'https://beentos.herokuapp.com/api' : 'https://beentos-brand-api-54b5b6f89390.herokuapp.com/api'
export const baseUrl = "https://beentos-brand-api-54b5b6f89390.herokuapp.com/api";


const instance = axios.create({
  baseURL: baseUrl,
});

export default instance;
