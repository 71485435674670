import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { ai, beentos_ai, needHelp } from '../../../components/images';
import useDocumentTitle from '../../../helpers/pageTitle';
import { decryptData } from '../../../helpers/security';
import { useTypedSelector } from '../../../hooks/useSelector';
import { ActionType } from '../../../store/actions';
import Steps from '../../../components/signup/steps';
import { AiModal, ChallengeSteps } from '../../../components/brand';
import Input from '../../../components/input';
import Button from '../../../components/button';
import { BsSend } from 'react-icons/bs';
import instance from '../../../helpers/axios';
import { iChallenge } from '../../../models/model';
import { isAdmin, isEdit } from '../../../helpers/helpers';
import { toast, ToastContainer } from 'react-toastify';
import PickColorModal from '../../../components/brand/pickColorModal';
import BSTooltip from '../../../components/tooltip';

const CreateChallenge: React.FC = () => {
    const { loggedInBrand, loggedInAdmin } = useTypedSelector((state) => state.appReducer);
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const [title, setTitle] = useState("📢 Attention Amazing Customers! 📢")
    const [showTitleErr, setShowTitleErr] = useState(false)
    const [titleERr, setTitleERr] = useState('')
    const [loading, setLoading] = useState(false)
    const { acctSideBarActive } = useTypedSelector((state) => state.appReducer);
    const { challengeData }: any = useTypedSelector((state) => state.appReducer);
    const [askai, setAskai] = useState('')
    const [challengeId, setChallengeId] = useState('')
    const [defaultColor, setDefaultColor] = useState('#0b0149')
    const [brandColor, setBrandColor] = useState('null')
    const [showAiModal, setShowAiModal] = useState(false)
    const [pickColor, setPickColor] = useState(false)
    const { pathname, search } = useLocation();
    const params = useParams();
    useDocumentTitle('CREATE CHALLENGE ')

    useEffect(() => {
        if(window.location.href.includes("admin")){
            dispatch({
                type: ActionType.UPDATE_LAYOUT,
                payload: "admin"
            })
        }else{
            
            dispatch({
                type: ActionType.UPDATE_LAYOUT,
                payload: "brand"
            })
        }

    }, [])

    useEffect(() => {
        setLoading(true)
        const search_ = (search.replace('?', '')).split("&")
        if (search_.length > 0) {
            search_.forEach(s => {
                if (s.includes('challenge=') !== false) {
                    const challenge = s.replace('challenge=', '')
                    setChallengeId(challenge)

                    const challengeurl = `/challenge/single/${challenge}/0/1`
                    instance({
                        method: isEdit() ? "POST" : "GET",
                        url: isEdit() ? challengeurl : `/challenge/draft/single/${challenge}`,
                        headers: headers
                    }).then((response) => {
                        console.log("response", response)
                        if (isEdit()) {
                            var cha = response.data.data.challenge;
                        } else {
                            var cha = response.data.data;
                        }
                        setTitle(cha.title)
                    }).catch((err) => {
                        console.log(err)
                    }).finally(() => {
                        setLoading(false)
                    })
                } else setLoading(false)
            });
        } else {
            setLoading(false)
            setTitle("📢 Attention Amazing Customers! 📢")
        }

    }, []);



    useEffect(() => {
        if(window.location.href.includes("admin")){

        }else{

        if (!loggedInBrand || loggedInBrand == "") {
            // nobody is logged in
            navigate("/brand/login");
        }

        instance({
            method: "GET",
            url: "/brand/profile",
            headers: headers
        }).then((response) => {
            const b = response.data.data.brand;
            if(b.brandColor) { setBrandColor(b.brandColor)}
            else{
                setBrandColor("")
                setPickColor(true)
            }
        })
        }

    }, [loggedInBrand])
    const headers = {
        'Authorization': `Bearer ${window.location.href.includes("admin") ? loggedInAdmin :loggedInBrand}`
    }
    const proceed = (action = "save") => {
        let passed = true;

        setLoading(true)
        setTimeout(() => {

            if (title == "") {
                setShowTitleErr(true)
                setTitleERr("Challenge title cannot be empty")
                passed = false;
            }

            if (!passed) {
                setLoading(false);
            } else {

                const data: iChallenge = {
                    title: title,
                    creationStatus: isEdit() ? "complete" : "draft",
                    lastStep: 1
                };

                dispatch({
                    type: ActionType.UPDATE_CHALLENGE,
                    payload: JSON.stringify(data)
                })

                if (challengeId !== "") {
                    data.challengeId = challengeId
                }
                if(window.location.href.includes("admin")){
                    data.brandId = params.brandid?.toString()
                }

                let url = window.location.href.includes("admin") ? '/challenge/admin/create' : '/challenge/create'
                let editUrl = isAdmin() ? '/challenge/admin/update' : "/challenge/update";
                instance({
                    url: isEdit() ? editUrl : url,
                    method: isEdit() ? "PUT" : "POST",
                    headers: headers,
                    data: data
                })
                    .then((response) => {
                        setTitle("")
                        if (isEdit()) { }
                        else {
                            data.challengeId = response.data.data._id;
                            setChallengeId(response.data.data._id)
                            dispatch({
                                type: ActionType.UPDATE_CHALLENGE,
                                payload: JSON.stringify(data)
                            })
                        }
                        // setTitle("")
                        if (isEdit()) {
                            if (action == "exit") {
                                toast.success("Challenge updated")
                                setTimeout(() => {
                                    if(isAdmin()) {
                                        navigate(`/admin/campaign-management`)
                                    }else{
                                        navigate(`/brand/challenge/`)
                                    }
                                }, 2000);
                            } else {
                                if(isAdmin()) {
                                    navigate(`/admin/edit-challenge/${params.brandid}/edit-step-2?challenge=${(response.data.data._id)}`);
                                }else{
                                    navigate(`/brand/challenge/edit-step-2?challenge=${(challengeId)}`);
                                }
                            }
                        } else {
                            if(window.location.href.includes("admin")) {
                                navigate(`/admin/create-challenge/${params.brandid}/step-2?challenge=${(response.data.data._id)}`);
                            }else{

                                navigate(`/brand/challenge/create-step-2?challenge=${(response.data.data._id)}`);
                            }

                        }
                    }).catch((err) => {
                        toast.error("Something went wrong")
                    })
                // write logic
            }
        }, 2000);

    }

    const setChallengeTitle = (value: string) => {
        if (value.length <= 80) {
            setTitle(value);
        } else {
            setShowTitleErr(true)
            setTitleERr("Title cannot be more than 80 characters");
        }
    }
    // const a = []
    return (
        <div className='px-[5px] py-[50px]'>
            <div className="container mx-auto">
                {
                    showAiModal ? <AiModal newChat={askai} close={() => {
                        setShowAiModal(false)
                        setAskai("");
                    }} /> : ''
                }
                {
                    brandColor == "" && <PickColorModal close={(e:string)=> {
                        setBrandColor(e)
                    }} />
                }
                <ToastContainer />
                <br />
                {/* <div className="flex items-center justify-between ">
                    <div className="text-blue font-primarybold text-[18px]">{isEdit() ? title : "Create New Event"}</div>
                    <img style={{ cursor: 'pointer' }} src={beentos_ai} onClick={() => {
                        setShowAiModal(true)
                    }} className="object-contain h-[30px]" alt="" />
                </div> */}
                {/* <div className={`control bg-white mb-3 mt-3 relative border flex items-center border-blue pl-2 h-[50px] rounded-[45px] w-full`}>
                    <div className="ml-3 mr-2">
                        <img src={ai} alt="" className='object-contain' />
                    </div>
                    <input onFocus={() => {
                        if (askai === "") setAskai("Create a Beentos Campaign");
                    }} placeholder="Ask AI" type='text' value={askai} onChange={(e) => setAskai(e.target.value)} className={`bg-transparent font-primary font-semibold ${askai === '' ? 'font-medium text-blue opacity-50' : 'dark:text-white  text-blue'} placeholder:font-medium  border-0 outline-0 bg-none w-full px-2 h-[50px] is-dirty peer`} />
                    <div style={{ cursor: 'pointer' }} className="absolute right-[15px] top-[15px] text-blue opacity-80" onClick={() => {
                        if (askai === "") return false;
                        setShowAiModal(true)
                    }}>
                        <BsSend />
                    </div>
                </div> */}
                <div className="py-[40px] bg-white shadow-lg rounded ">
                    {/* <ChallengeSteps current={1} /> */}
                    <br />
                    <div className="lg:mt-[0px] text-center">
                        <div className="flex items-center justify-center">
                        <h3 className='text-blue text-[24px] font-bold font-primary'>What will you call this Event? 
                            </h3>
                           <BSTooltip tooltip={"This is the title of your Event"} />
                            </div>
                        <div className="mt-4 w-[80%] mx-auto">
                            <Input err={titleERr} showError={showTitleErr} placeholder="Type here" value={title} setValue={setChallengeTitle} containerClass="mt-[22px] border-graylight border-2 shadow-sm" />
                            {
                                isEdit() ?
                                    <div className="flex justify-center">
                                        <Button loading={loading} text="Save" onclick={() => proceed()} containerClass="w-[150px] bg-blue text-yellow mt-[30px]" />
                                        <div className="mx-2"></div>
                                        <Button loading={loading} text="Save and Exit" onclick={() => proceed("exit")} containerClass="w-[150px] bg-blue text-yellow mt-[30px]" />

                                    </div>
                                    :
                                    <Button loading={loading} text="Next" onclick={() => proceed()} containerClass="w-[150px] bg-blue text-yellow mt-[30px]" />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreateChallenge